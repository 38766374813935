<template>
  <div v-if="!isPageVisible">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>Resource Not Available</h3>
    </b-card>
  </div>
  <div v-else-if="user_type === 'agent'">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>"Previledge access only to Aliff Franchise."</h3>
    </b-card>
  </div>
  <b-card v-else style="background-color: #fcfcfc">

    <b-tabs content-class="mt-3">
      <b-tab title="Industry News" active>
        <div v-if="industry_news.length > 0">
          <b-card class="border p-2" v-for="(news, index) in industry_news" :key="'industry_news_' + index"
            style="background-color: white">
            <h3>{{ news.fin_name }}</h3>
            <div v-html="news.fin_text" class="mt-2" style="
                border-radius: 1rem;
                padding: 2% 2% 2% 2%;
                border-color: #000;
                border-width: 1px;
                border-style: solid;
              "></div>
          </b-card>
        </div>
        <template v-else>
          <h4 class="pl-2">No Available Industry News.</h4>
        </template>
      </b-tab>
      <b-tab title="Material">
        <div v-if="materials.length > 0">
          <b-card class="border p-2" v-for="(material, index) in materials" :key="'materials_' + index"
            style="background-color: white">
            <h3>{{ material.fm_name }}</h3>
            <div v-html="material.fm_text" class="mt-2" style="
                border-radius: 1rem;
                padding: 2% 2% 2% 2%;
                border-color: #000;
                border-width: 1px;
                border-style: solid;
              "></div>
          </b-card>
        </div>
        <template v-else>
          <h4 class="pl-2">No Available Material.</h4>
        </template>
      </b-tab>
      <b-tab title="Report and Analysis"></b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: { BCard, AppCollapse, AppCollapseItem, BTab, BTabs },
  data() {
    return {
      collaterals: [],
      industry_news: [],
      materials: [],
      user_type:null
    };
  },
  computed: {
    isPageVisible() {
      const user_details = this.$store.getters["user/getUserDetails"];
      this.user_type = user_details.user_type
      if (user_details && Array.isArray(user_details.allocated_resources)) {

        const allowcatedResource = user_details.allocated_resources.find((elem) => elem === 'marketing_insight')
        if (allowcatedResource) {
          this.getFranchiseIndustryNews();
          this.getFranchiseMaterial();
          return true
        }
        return false
      }
      return false
    }
  },
  methods: {
    async getFranchiseMaterial() {
      try {
        const response = await AgentServices.getFranchiseMaterial();
        this.materials = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseMaterial`);
      }
    },

    async getFranchiseIndustryNews() {
      try {
        const response = await AgentServices.getFranchiseIndustryNews();
        this.industry_news = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseIndustryNews`);
      }
    },
  },
  beforeMount() {

  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}
</style>
